"use client";

import { AppInitialization } from "./AppInitialization";
import StoreProvider from "./StoreProvider";

const Providers = ({ children }) => {
  return (
    <StoreProvider>
      <AppInitialization>{children}</AppInitialization>
    </StoreProvider>
  );
};

export default Providers;
